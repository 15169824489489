<template>
    <v-container
        id="users"
        fluid
        class="fill-height flex-column flex-nowrap align-start"
    >
        <v-container fluid class="pt-5 px-5 mb-10">
            <v-row justify="space-between">
                <v-col cols="12" md="7" class="d-flex">
                    <v-toolbar-title
                        class="headline font-weight-bold accent--text"
                        >Settings</v-toolbar-title
                    >
                    <v-select
                        v-model="selectedSettingPage"
                        background-color="transparent"
                        item-text="text"
                        item-value="value"
                        :menu-props="{ top: false, offsetY: true }"
                        class="ma-0 pa-0 ml-10 ministry-pages"
                        :items="settingItems"
                        @change="changeView"
                        color="primaryTextColor"
                        :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                    ></v-select>
                </v-col>

                <v-col
                    md="5"
                    cols="12"
                    class="d-inline-flex flex-row justify-space-between"
                >
                    <v-select
                        solo
                        rounded
                        class="rounded-lg"
                        flat
                        hide-details
                        dense
                        :menu-props="{ top: true, offsetY: true }"
                        placeholder="Membership"
                        color="secondary"
                        item-value="value"
                        item-text="name"
                        :items="memberships"
                        style="max-width: 15rem"
                        clearable
                        small-chips
                        @click:clear="table.options.membership = null"
                        v-model="table.options.membership"
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip color="secondary" small label>
                                {{ item.name }}
                            </v-chip>
                        </template>
                    </v-select>
                    <div class="mx-1"></div>
                    <v-select
                        solo
                        rounded
                        class="rounded-lg"
                        flat
                        hide-details
                        dense
                        :menu-props="{ top: true, offsetY: true }"
                        placeholder="Designation"
                        color="secondary"
                        item-value="value"
                        item-text="name"
                        :items="designations"
                        style="max-width: 15rem"
                        small-chips
                        clearable
                        @click:clear="table.options.designation = null"
                        v-model="table.options.designation"
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip color="secondary" small label>
                                {{ item.name }}
                            </v-chip>
                        </template>
                    </v-select>
                    <div class="mx-1"></div>
                    <v-select
                        solo
                        rounded
                        class="rounded-lg"
                        flat
                        hide-details
                        dense
                        :menu-props="{ top: true, offsetY: true }"
                        placeholder="Role"
                        color="secondary"
                        item-value="value"
                        item-text="name"
                        :items="roles"
                        small-chips
                        style="max-width: 15rem"
                        clearable
                        @click:clear="table.options.role = null"
                        v-model="table.options.role"
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip color="secondary" small label>
                                {{ item.name }}
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col md="6" cols="12" class="d-flex">
                    <v-text-field
                        class="rounded-lg"
                        placeholder="Search"
                        solo
                        rounded
                        flat
                        hide-details
                        dense
                        color="secondary"
                        v-model="table.options.search"
                    ></v-text-field>
                    <div class="mx-2"></div>
                    <v-btn
                        class="border-radius-25 px-5 mx-1"
                        depressed
                        rounded
                        dark
                        color="accent"
                        width=""
                        @click="getUsers"
                    >
                        Search
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container
            fluid
            class="mt-n4 flex-grow-1 fill-height align-start justify-center"
        >
            <v-row>
                <v-col>
                    <v-data-table
                        class="custom-table"
                        :headers="table.headers"
                        :loading="table.loading"
                        :items="table.items"
                        :server-items-length="table.pagination.total"
                        :items-per-page.sync="table.pagination.perPage"
                        :page.sync="table.pagination.page"
                        :footer-props="{
                            'items-per-page-options':
                                table.pagination.itemsPerPageOptions,
                        }"
                        hide-default-footer
                    >
                        <template v-slot:item.middle_name="{ item }">
                            {{ item.middle_name || "--" }}
                        </template>

                        <template v-slot:item.suffix="{ item }">
                            {{ item.suffix || "--" }}
                        </template>

                        <template v-slot:item.nickname="{ item }">
                            {{ item.nickname || "--" }}
                        </template>

                        <template v-slot:item.designation="{ item }">
                            <template
                                v-for="(
                                    designation, index
                                ) in extractRolesDesignations(
                                    item.user_designations,
                                    'designation'
                                )"
                            >
                                <v-chip
                                    color="secondary"
                                    small
                                    label
                                    class="mr-1"
                                    :key="index"
                                >
                                    {{ designation }}
                                </v-chip>
                            </template>
                        </template>

                        <template v-slot:item.membership="{ item }">
                            {{ item.user_information.membership.name || "--" }}
                        </template>

                        <template v-slot:item.role="{ item }">
                            <template
                                v-for="(
                                    role, index
                                ) in extractRolesDesignations(
                                    item.user_roles,
                                    'role'
                                )"
                            >
                                <v-chip
                                    color="secondary"
                                    small
                                    label
                                    class="mr-1"
                                    :key="index"
                                >
                                    {{ role }}
                                </v-chip>
                            </template>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-btn
                                color="grey lighten-2"
                                class="border-radius-20"
                                elevation="0"
                                small
                                @click="openAccessFormDialog(item)"
                            >
                                <v-icon color="#78756A">mdi-fullscreen</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                    <Pagination
                        :total-pages="table.pagination.lastPage"
                        :current-page="table.pagination.currentPage"
                        @change="onPaginationChange"
                    />
                </v-col>
            </v-row>

            <settings-access-form-dialog
                :show.sync="accessForm.show"
                :data="accessForm.data"
                @close="accessForm = { ...accessForm, show: false, data: null }"
                @onSuccess="getUsers"
            ></settings-access-form-dialog>
        </v-container>
    </v-container>
</template>

<script>
import { GET_USERS } from "@/store/modules/user";
import Pagination from "@/components/Core/Pagination";
import SettingsAccessFormDialog from "@/components/MainView/Settings/AccessControl/forms/AccessFormDialog";
import helperUtility from "@/mixins/helper";
import { GET_DESIGNATIONS } from "@/store/modules/designation";
import { GET_ROLES } from "@/store/modules/role";
import { GET_PROFILE_MEMBERSHIPS } from "@/store/modules/profile";

export default {
    name: "settings-access-control",

    components: { SettingsAccessFormDialog, Pagination },

    data() {
        return {
            accessForm: { show: false, data: null },
            table: {
                items: [],
                search: null,
                loading: false,
                pagination: {},
                options: {
                    membership: null,
                    designation: null,
                    role: null,
                    accessControlUsers: true,
                },
                headers: [
                    { text: "Last Name", value: "last_name", sortable: false },
                    {
                        text: "First Name",
                        value: "first_name",
                        sortable: false,
                    },
                    {
                        text: "Middle Name",
                        value: "middle_name",
                        sortable: false,
                    },
                    { text: "Suffix", value: "suffix", sortable: false },
                    { text: "Nickname", value: "nickname", sortable: false },
                    {
                        text: "Membership",
                        value: "membership",
                        sortable: false,
                    },
                    {
                        text: "Designation",
                        value: "designation",
                        sortable: false,
                    },
                    {
                        text: "Role",
                        value: "role",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "action",
                        sortable: false,
                        width: "130px",
                    },
                ],
            },
            designations: [],
            memberships: [],
            roles: [],
            selectedSettingPage: "/settings/access-control",
            settingItems: [
                {
                    text: "Access Controls",
                    value: "/settings/access-control",
                },
                {
                    text: "Banners",
                    value: "/settings/banner",
                },
            ],
        };
    },

    watch: {
        ["table.search"]: helperUtility.debounce(async function () {
            this.table.pagination.page = 1;
            await this.getUsers();
        }, 500),

        ["table.options"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getUsers();
            },
            deep: true,
        },
    },

    methods: {
        async getUsers() {
            const payload = {
                ...this.table.pagination,
                ...this.table.options,
            };
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_USERS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
            await this.getUsers();
        },

        extractRolesDesignations(collection, property) {
            if (collection && collection.length > 0) {
                return collection.map((item) => item[property].name);
            }
            return null;
        },

        openAccessFormDialog(access) {
            this.accessForm = { show: true, data: Object.assign({}, access) };
        },

        async getDesignations() {
            const { data } = await this.$store.dispatch(GET_DESIGNATIONS);
            this.designations = data;
        },

        async getMemberships() {
            const { data } = await this.$store.dispatch(
                GET_PROFILE_MEMBERSHIPS
            );
            this.memberships = data;
        },

        async getRoles() {
            const { data } = await this.$store.dispatch(GET_ROLES);
            this.roles = data;
        },

        changeView(to) {
            this.$router.push(to);
        },

        async initiate() {
            await this.getUsers();
            await this.getDesignations();
            await this.getMemberships();
            await this.getRoles();
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.v-select::v-deep .v-select__selection--comma {
    color: #78756a;
}
</style>
